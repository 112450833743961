/* Estilo del scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* background-color: #838383; */
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c0c0c0;
}
